import React from "react";
import "./App.css";

function App() {
  return (
    <section>
      <h1>Logs</h1>
      <ul>
        <li>Project created at Sep 7 2022, 9:48AM</li>
        <li>This line was added by the PR #1.</li>
        <li>This line was added by the PR #8.</li>
        <li>This line was added by the PR #10 and updated by hotfix #1/#2.</li>
        <li>This line was added by the PR #12 and updated by the PR #15.</li>
        <li>This line was added by gihyeon</li>
        <li>This line was added by gihyeon (hotfix)</li>
        <li>This line was added by kabdo in PR #28.</li>
        <li>This line was updated to test RC process.</li>
        <li>
          This line was added by kabdo in PR #48 and updated by hotfix again!
        </li>
        <li>This line was added by gihyeon (2)</li>
        <li>This line was added by gihyeon (3)</li>
        <li>This line was added by gihyeon (4)</li>
        <li>This line was added by kabdo (2)</li>
        <li>This line was added by gihyeon (5) - hotfix</li>
        <li>This line was added by gihyeon (6) - hotfix</li>
        <li>This line was added by gihyeon - hotfix - 20220913 15:26</li>
        <li>This line was added by gihyeon - hotfix - 20220913 15:39</li>
        <li>This line was added by kabdo (3) and updated by hotfix</li>
        <li>This line was added by kabdo (4)</li>
        <li>***Feature A***</li>
        <li>DEMO Hotfix</li>
      </ul>
    </section>
  );
}

export default App;
